/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  AbsoluteTime,
  Amounts,
  TalerError,
  parsePaytoUri,
} from "@gnu-taler/taler-util";
import { useTransactions } from "../../hooks/account.js";
import { Props, State, Transaction } from "./index.js";

export function useComponentState({
  account,
  routeCreateWireTransfer,
}: Props): State {
  const txResult = useTransactions(account);
  if (!txResult) {
    return {
      status: "loading",
      error: undefined,
    };
  }
  if (txResult instanceof TalerError) {
    return {
      status: "loading-error",
      error: txResult,
    };
  }

  const transactions = txResult.result
    .map((tx) => {
      const negative = tx.direction === "debit";
      const cp = parsePaytoUri(
        negative ? tx.creditor_payto_uri : tx.debtor_payto_uri,
      );
      const counterpart =
        (cp === undefined || !cp.isKnown
          ? undefined
          : cp.targetType === "iban"
            ? cp.iban
            : cp.targetType === "x-taler-bank"
              ? cp.account
              : cp.targetType === "bitcoin"
                ? `${cp.address.substring(0, 6)}...`
                : undefined) ?? "unknown";

      const when = AbsoluteTime.fromProtocolTimestamp(tx.date);
      const amount = Amounts.parse(tx.amount);
      const subject = tx.subject;
      return {
        negative,
        counterpart,
        when,
        amount,
        subject,
      };
    })
    .filter((x): x is Transaction => x !== undefined);

  return {
    status: "ready",
    error: undefined,
    routeCreateWireTransfer,
    transactions,
    onGoNext: txResult.isLastPage ? undefined : txResult.loadNext,
    onGoStart: txResult.isFirstPage ? undefined : txResult.loadFirst,
  };
}
