/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { parseWithdrawUri, stringifyWithdrawUri } from "@gnu-taler/taler-util";
import { Attention, useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { useBankCoreApiContext } from "@gnu-taler/web-util/browser";
import { useBankState } from "../hooks/bank-state.js";
import { RouteDefinition } from "@gnu-taler/web-util/browser";
import { WithdrawalQRCode } from "./WithdrawalQRCode.js";

export function WithdrawalOperationPage({
  operationId,
  onAuthorizationRequired,
  onOperationAborted,
  routeClose,
  routeWithdrawalDetails,
}: {
  onAuthorizationRequired: () => void;
  operationId: string;
  purpose: "after-creation" | "after-confirmation";
  onOperationAborted: () => void;
  routeClose: RouteDefinition;
  routeWithdrawalDetails: RouteDefinition<{ wopid: string }>;
}): VNode {
  const { lib: { bank: api } } = useBankCoreApiContext();
  const uri = stringifyWithdrawUri({
    bankIntegrationApiBaseUrl: api.getIntegrationAPI().href,
    withdrawalOperationId: operationId,
  });
  const parsedUri = parseWithdrawUri(uri);
  const { i18n } = useTranslationContext();
  const [, updateBankState] = useBankState();

  if (!parsedUri) {
    return (
      <Attention
        type="danger"
        title={i18n.str`The Withdrawal URI is not valid`}
      >
        {uri}
      </Attention>
    );
  }

  return (
    <WithdrawalQRCode
      withdrawUri={parsedUri}
      routeWithdrawalDetails={routeWithdrawalDetails}
      onAuthorizationRequired={onAuthorizationRequired}
      onOperationAborted={() => {
        updateBankState("currentWithdrawalOperationId", undefined);
        onOperationAborted();
      }}
      routeClose={routeClose}
    />
  );
}
