/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { Cashouts } from "../../components/Cashouts/index.js";
import { useSessionState } from "../../hooks/session.js";
import { ProfileNavigation } from "../ProfileNavigation.js";
import { CreateCashout } from "../regional/CreateCashout.js";
import { RouteDefinition } from "@gnu-taler/web-util/browser";

interface Props {
  account: string;
  routeClose: RouteDefinition;
  onAuthorizationRequired: () => void;
  routeCashoutDetails: RouteDefinition<{ cid: string }>;
  routeMyAccountDetails: RouteDefinition;
  routeMyAccountDelete: RouteDefinition;
  routeMyAccountPassword: RouteDefinition;
  routeMyAccountCashout: RouteDefinition;
  routeCreateCashout: RouteDefinition;
  routeConversionConfig: RouteDefinition;
}

export function CashoutListForAccount({
  account,
  onAuthorizationRequired,
  routeCreateCashout,
  routeCashoutDetails,
  routeMyAccountCashout,
  routeMyAccountDelete,
  routeMyAccountDetails,
  routeConversionConfig,
  routeMyAccountPassword,
  routeClose,
}: Props): VNode {
  const { i18n } = useTranslationContext();

  const { state: credentials } = useSessionState();

  const accountIsTheCurrentUser =
    credentials.status === "loggedIn"
      ? credentials.username === account
      : false;

  return (
    <Fragment>
      {accountIsTheCurrentUser ? (
        <ProfileNavigation
          current="cashouts"
          routeMyAccountCashout={routeMyAccountCashout}
          routeMyAccountDelete={routeMyAccountDelete}
          routeMyAccountDetails={routeMyAccountDetails}
          routeMyAccountPassword={routeMyAccountPassword}
          routeConversionConfig={routeConversionConfig}
        />
      ) : (
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          <i18n.Translate>Cashout for account {account}</i18n.Translate>
        </h1>
      )}

      <CreateCashout
        focus
        routeHere={routeCreateCashout}
        routeClose={routeClose}
        onAuthorizationRequired={onAuthorizationRequired}
        account={account}
      />

      <Cashouts account={account} routeCashoutDetails={routeCashoutDetails} />
    </Fragment>
  );
}
