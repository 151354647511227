/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
export interface StringsType {
  // X-Domain or 'messages'
  domain: string;
  lang: string;
  completeness: number;
  plural_forms: string;
  locale_data: {
    messages: Record<string, unknown>;
  };
}
export const strings: Record<string, StringsType> = {};

strings["it"] = {
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        plural_forms: "nplurals=2; plural=n != 1;",
        lang: "it",
      },
      "Operation failed, please report": ["Registrazione"],
      "Request timeout": [""],
      "Request throttled": [""],
      "Malformed response": [""],
      "Network error": [""],
      "Unexpected request error": [""],
      "Unexpected error": [""],
      "IBAN numbers usually have more that 4 digits": [""],
      "IBAN numbers usually have less that 34 digits": [""],
      "IBAN country code not found": [""],
      "IBAN number is not valid, checksum is wrong": [""],
      "Max withdrawal amount": ["Questo ritiro è stato annullato!"],
      "Show withdrawal confirmation": ["Questo ritiro è stato annullato!"],
      "Show demo description": [""],
      "Show install wallet first": [""],
      "Use fast withdrawal form": ["Ritira contante"],
      "Show debug info": [""],
      "The reserve operation has been confirmed previously and can't be aborted":
        [""],
      "The operation id is invalid.": [""],
      "The operation was not found.": ["Lista conti pubblici non trovata."],
      "If you have a Taler wallet installed in this device": [""],
      "You will see the details of the operation in your wallet including the fees (if applies). If you still don't have one you can install it following instructions in":
        [""],
      "this page": [""],
      Withdraw: ["Prelevare"],
      "Or if you have the wallet in another device": [""],
      "Scan the QR below to start the withdrawal.": ["Chiudi il ritiro Taler"],
      required: [""],
      "IBAN should have just uppercased letters and numbers": [""],
      "not valid": [""],
      "should be greater than 0": [""],
      "balance is not enough": [""],
      "does not follow the pattern": [""],
      'only "IBAN" target are supported': [""],
      'use the "amount" parameter to specify the amount to be transferred': [
        "",
      ],
      "the amount is not valid": [""],
      'use the "message" parameter to specify a reference text for the transfer':
        [""],
      "The request was invalid or the payto://-URI used unacceptable features.":
        [""],
      "Not enough permission to complete the operation.": [
        "La banca sta creando l'operazione...",
      ],
      'The destination account "%1$s" was not found.': [
        "Lista conti pubblici non trovata.",
      ],
      "The origin and the destination of the transfer can't be the same.": [""],
      "Your balance is not enough.": [""],
      'The origin account "%1$s" was not found.': [
        "Lista conti pubblici non trovata.",
      ],
      "Using a form": [""],
      "Import payto:// URI": [""],
      Recipient: [""],
      "IBAN of the recipient's account": [""],
      "Transfer subject": [
        "Trasferisci fondi a un altro conto di questa banca:",
      ],
      subject: ["Soggetto"],
      "some text to identify the transfer": [""],
      Amount: ["Importo"],
      "amount to transfer": ["Somma da ritirare"],
      "payto URI:": [""],
      "uniform resource identifier of the target account": [""],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [""],
      Cancel: [""],
      Send: [""],
      "Missing username": [""],
      "Missing password": [""],
      'Wrong credentials for "%1$s"': ["Credenziali invalide."],
      "Account not found": [""],
      Username: [""],
      "username of the account": [
        "Trasferisci fondi a un altro conto di questa banca:",
      ],
      Password: [""],
      "password of the account": ["Storico dei conti pubblici"],
      Check: [""],
      "Log in": [""],
      Register: ["Registrati"],
      "Wire transfer completed!": ["Bonifico"],
      "The withdrawal has been aborted previously and can't be confirmed": [""],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.":
        [""],
      "Your balance is not enough for the operation.": [""],
      "Confirm the withdrawal operation": ["Conferma il ritiro"],
      "Wire transfer details": ["Bonifico"],
      "Taler Exchange operator's account": [""],
      "Taler Exchange operator's name": [""],
      Transfer: [""],
      "Authentication required": [""],
      "This operation was created with other username": [""],
      "Operation aborted": [""],
      "The wire transfer to the Taler Exchange operator's account was aborted, your balance was not affected.":
        [""],
      "You can close this page now or continue to the account page.": [""],
      Continue: [""],
      "Withdrawal confirmed": ["Questo ritiro è stato annullato!"],
      "The wire transfer to the Taler operator has been initiated. You will soon receive the requested amount in your Taler wallet.":
        [""],
      Done: [""],
      "Operation canceled": [""],
      "The operation is marked as 'selected' but some step in the withdrawal failed":
        [""],
      "The account is selected but no withdrawal identification found.": [""],
      "There is a withdrawal identification but no account has been selected or the selected account is invalid.":
        [""],
      "No withdrawal ID found and no account has been selected or the selected account is invalid.":
        [""],
      "Operation not found": [""],
      "This operation is not known by the server. The operation id is wrong or the server deleted the operation information before reaching here.":
        [""],
      "Cotinue to dashboard": [""],
      "The Withdrawal URI is not valid": ["Questo ritiro è stato annullato!"],
      'the bank backend is not supported. supported version "%1$s", server version "%2$s"':
        [""],
      "Internal error, please report.": ["Registrazione"],
      Preferences: [""],
      "Welcome, %1$s": [""],
      "Latest transactions": ["Ultime transazioni:"],
      Date: ["Data"],
      Counterpart: ["Controparte"],
      Subject: ["Soggetto"],
      sent: [""],
      received: [""],
      "invalid value": [""],
      to: [""],
      from: [""],
      "First page": [""],
      Next: [""],
      "History of public accounts": ["Storico dei conti pubblici"],
      "Currently, the bank is not accepting new registrations!": [""],
      "Missing name": ["indirizzo Payto"],
      "Use letters and numbers only, and start with a lowercase letter": [""],
      "Passwords don't match": [""],
      "Server replied with invalid phone or email.": [""],
      "Registration is disabled because the bank ran out of bonus credit.": [
        "",
      ],
      "No enough permission to create that account.": [""],
      "That account id is already taken.": [""],
      "That username is already taken.": [""],
      "That username can't be used because is reserved.": [""],
      "Only admin is allow to set debt limit.": [""],
      "No information for the selected authentication channel.": [""],
      "Authentication channel is not supported.": [""],
      "Only admin can create accounts with second factor authentication.": [""],
      "Account registration": [""],
      "Repeat password": [""],
      Name: [""],
      "Create a random temporary user": [""],
      "Make a wire transfer": ["Chiudi il bonifico"],
      "Wire transfer created!": ["Bonifico"],
      Accounts: ["Importo"],
      "A list of all business account in the bank.": [""],
      "Create account": [""],
      Balance: [""],
      Actions: [""],
      unknown: [""],
      "change password": [""],
      remove: [""],
      "Select a section": [""],
      "Last hour": [""],
      "Last day": [""],
      "Last month": [""],
      "Last year": [""],
      "Last Year": [""],
      "Trading volume on %1$s compared to %2$s": [""],
      Cashin: [""],
      Cashout: [""],
      Payin: [""],
      Payout: [""],
      "download stats as CSV": [""],
      "Descreased by": [""],
      "Increased by": [""],
      "Unable to create a cashout": [""],
      "The bank configuration does not support cashout operations.": [""],
      invalid: [""],
      "need to be higher due to fees": [""],
      "the total transfer at destination will be zero": [""],
      "Cashout created": [""],
      "Duplicated request detected, check if the operation succeded or try again.":
        [""],
      "The conversion rate was incorrectly applied": [""],
      "The account does not have sufficient funds": [""],
      "Cashouts are not supported": [""],
      "Missing cashout URI in the profile": [""],
      "Sending the confirmation message failed, retry later or contact the administrator.":
        [""],
      "Convertion rate": [""],
      Fee: [""],
      "To account": [""],
      "No cashout account": [""],
      "Before doing a cashout you need to complete your profile": [""],
      "Amount to send": ["Somma da ritirare"],
      "Amount to receive": ["Somma da ritirare"],
      "Total cost": [""],
      "Balance left": [""],
      "Before fee": [""],
      "Total cashout transfer": [""],
      "No cashout channel available": [""],
      "Before doing a cashout the server need to provide an second channel to confirm the operation":
        [""],
      "Second factor authentication": [""],
      Email: [""],
      "add a email in your profile to enable this option": [""],
      SMS: [""],
      "add a phone number in your profile to enable this option": [""],
      Details: [""],
      Delete: [""],
      Credentials: ["Credenziali invalide."],
      Cashouts: [""],
      "it doesnt have the pattern of an IBAN number": [""],
      "it doesnt have the pattern of an email": [""],
      "should start with +": [""],
      "phone number can't have other than numbers": [""],
      "account identification in the bank": [""],
      "name of the person owner the account": [""],
      "Internal IBAN": [""],
      "if empty a random account number will be assigned": [""],
      "account identification for bank transfer": [""],
      Phone: [""],
      "Cashout IBAN": [""],
      "account number where the money is going to be sent when doing cashouts":
        [""],
      "Max debt": [""],
      "how much is user able to transfer after zero balance": [""],
      "Is this a Taler Exchange?": [""],
      "This server doesn't support second factor authentication.": [""],
      "Enable second factor authentication": [""],
      "Using email": [""],
      "Using SMS": [""],
      "Is this account public?": [""],
      "public accounts have their balance publicly accesible": [""],
      "Account updated": [""],
      "The rights to change the account are not sufficient": [""],
      "The username was not found": [""],
      "You can't change the legal name, please contact the your account administrator.":
        [""],
      "You can't change the debt limit, please contact the your account administrator.":
        [""],
      "You can't change the cashout address, please contact the your account administrator.":
        [""],
      "You can't change the contact data, please contact the your account administrator.":
        [""],
      'Account "%1$s"': [""],
      "Change details": [""],
      Update: [""],
      "password doesn't match": [""],
      "Password changed": [""],
      "Not authorized to change the password, maybe the session is invalid.": [
        "",
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.":
        [""],
      "Your current password doesn't match, can't change to a new password.": [
        "",
      ],
      "Update password": [""],
      "New password": [""],
      "Type it again": [""],
      "repeat the same password": [""],
      "Current password": [""],
      "your current password, for security": [""],
      Change: [""],
      "Can't delete the account": [""],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.":
        [""],
      "Account removed": [""],
      "No enough permission to delete the account.": [""],
      "The username was not found.": [""],
      "Can't delete a reserved username.": [""],
      "Can't delete an account with balance different than zero.": [""],
      "name doesn't match": [""],
      "You are going to remove the account": [""],
      "This step can't be undone.": [""],
      'Deleting account "%1$s"': [""],
      Verification: [""],
      "enter the account name that is going to be deleted": [""],
      'Account created with password "%1$s". The user must change the password on the next login.':
        [""],
      "Server replied that phone or email is invalid": [""],
      "The rights to perform the operation are not sufficient": [""],
      "Account username is already taken": [""],
      "Account id is already taken": [""],
      "Bank ran out of bonus credit.": [""],
      "Account username can't be used because is reserved": [""],
      "Can't create accounts": [""],
      "Only system admin can create accounts.": [""],
      "New business account": [""],
      Create: [""],
      "Cashout not supported.": [""],
      "Account not found.": ["Lista conti pubblici non trovata."],
      "Latest cashouts": ["Ultime transazioni:"],
      Created: [""],
      Confirmed: ["Conferma"],
      "Total debit": [""],
      "Total credit": [""],
      Status: [""],
      never: [""],
      "Cashout for account %1$s": [""],
      "This cashout not found. Maybe already aborted.": [""],
      "Cashout not found. It may be also mean that it was already aborted.": [
        "",
      ],
      "Cashout was already confimed.": [""],
      "Cashout operation is not supported.": [""],
      "The cashout operation is already aborted.": [""],
      "Missing destination account.": [""],
      "Too many failed attempts.": [""],
      "The code for this cashout is invalid.": [""],
      "Cashout detail": [""],
      Debited: [""],
      Credited: [""],
      "Enter the confirmation code": [""],
      Abort: ["Annulla"],
      Confirm: ["Conferma"],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.":
        [""],
      "The operation was rejected due to insufficient funds.": [""],
      "Do not show this again": [""],
      Close: [""],
      "On this device": [""],
      'If you are using a web browser on desktop you should access your wallet with the GNU Taler WebExtension now or click the link if your WebExtension have the "Inject Taler support" option enabled.':
        [""],
      Start: [""],
      "On a mobile phone": [""],
      "Scan the QR code with your mobile device.": [
        "Usa questo codice QR per ritirare contante nel tuo wallet:",
      ],
      "There is an operation already": [""],
      "Complete or cancel the operation in": ["Conferma il ritiro"],
      "Server responded with an invalid  withdraw URI": [""],
      "Withdraw URI: %1$s": ["Prelevare"],
      "The operation was rejected due to insufficient funds": [""],
      "Prepare your wallet": [""],
      "After using your wallet you will need to confirm or cancel the operation on this site.":
        [""],
      "You need a GNU Taler Wallet": ["Ritira contante nel portafoglio Taler"],
      "If you don't have one yet you can follow the instruction in": [""],
      "Send money": [""],
      "to a %1$s wallet": [""],
      "Withdraw digital money into your mobile wallet or browser extension": [
        "",
      ],
      "operation ready": [""],
      "to another bank account": [
        "Trasferisci fondi a un altro conto di questa banca:",
      ],
      "Make a wire transfer to an account with known bank account number.": [
        "",
      ],
      "Transfer details": ["Effettua un bonifico"],
      "This is a demo bank": [""],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s.":
        [""],
      "This part of the demo shows how a bank that supports Taler directly would work.":
        [""],
      "Pending account delete operation": [""],
      "Pending account update operation": [""],
      "Pending password update operation": [""],
      "Pending transaction operation": [""],
      "Pending withdrawal operation": [""],
      "Pending cashout operation": [""],
      "You can complete or cancel the operation in": [""],
      "Download bank stats": [""],
      "Include hour metric": [""],
      "Include day metric": [""],
      "Include month metric": [""],
      "Include year metric": [""],
      "Include table header": [""],
      "Add previous metric for compare": [""],
      "Fail on first error": [""],
      Download: [""],
      "downloading... %1$s": [""],
      "Download completed": [""],
      "click here to save the file in your computer": [""],
      "Challenge not found.": [""],
      "This user is not authorized to complete this challenge.": [""],
      "Too many attemps, try another code.": [""],
      "The confirmation code is wrong, try again.": [""],
      "The operation expired.": [""],
      "The operation failed.": ["Questo ritiro è stato annullato!"],
      "The operation needs another confirmation to complete.": [""],
      "Account delete": [""],
      "Account update": [""],
      "Password update": [""],
      "Wire transfer": ["Bonifico"],
      Withdrawal: ["Prelevare"],
      "Confirm the operation": ["Conferma il ritiro"],
      "Send again": [""],
      "Send code": [""],
      "Operation details": [""],
      "Challenge details": [""],
      "Sent at": [""],
      "To phone": [""],
      "To email": [""],
      "Welcome to %1$s!": [""],
    },
  },
  domain: "messages",
  plural_forms: "nplurals=2; plural=n != 1;",
  lang: "it",
  completeness: 14,
};

strings["fr"] = {
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        plural_forms: "nplurals=2; plural=n > 1;",
        lang: "fr",
      },
      "Operation failed, please report": [""],
      "Request timeout": [""],
      "Request throttled": [""],
      "Malformed response": [""],
      "Network error": [""],
      "Unexpected request error": [""],
      "Unexpected error": [""],
      "IBAN numbers usually have more that 4 digits": [""],
      "IBAN numbers usually have less that 34 digits": [""],
      "IBAN country code not found": [""],
      "IBAN number is not valid, checksum is wrong": [""],
      "Max withdrawal amount": [""],
      "Show withdrawal confirmation": [""],
      "Show demo description": [""],
      "Show install wallet first": [""],
      "Use fast withdrawal form": [""],
      "Show debug info": [""],
      "The reserve operation has been confirmed previously and can't be aborted":
        [""],
      "The operation id is invalid.": [""],
      "The operation was not found.": [""],
      "If you have a Taler wallet installed in this device": [""],
      "You will see the details of the operation in your wallet including the fees (if applies). If you still don't have one you can install it following instructions in":
        [""],
      "this page": [""],
      Withdraw: [""],
      "Or if you have the wallet in another device": [""],
      "Scan the QR below to start the withdrawal.": [""],
      required: [""],
      "IBAN should have just uppercased letters and numbers": [""],
      "not valid": [""],
      "should be greater than 0": [""],
      "balance is not enough": [""],
      "does not follow the pattern": [""],
      'only "IBAN" target are supported': [""],
      'use the "amount" parameter to specify the amount to be transferred': [
        "",
      ],
      "the amount is not valid": [""],
      'use the "message" parameter to specify a reference text for the transfer':
        [""],
      "The request was invalid or the payto://-URI used unacceptable features.":
        [""],
      "Not enough permission to complete the operation.": [""],
      'The destination account "%1$s" was not found.': [""],
      "The origin and the destination of the transfer can't be the same.": [""],
      "Your balance is not enough.": [""],
      'The origin account "%1$s" was not found.': [""],
      "Using a form": [""],
      "Import payto:// URI": [""],
      Recipient: [""],
      "IBAN of the recipient's account": [""],
      "Transfer subject": [""],
      subject: [""],
      "some text to identify the transfer": [""],
      Amount: [""],
      "amount to transfer": [""],
      "payto URI:": [""],
      "uniform resource identifier of the target account": [""],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [""],
      Cancel: [""],
      Send: [""],
      "Missing username": [""],
      "Missing password": [""],
      'Wrong credentials for "%1$s"': [""],
      "Account not found": [""],
      Username: [""],
      "username of the account": [""],
      Password: [""],
      "password of the account": [""],
      Check: [""],
      "Log in": [""],
      Register: [""],
      "Wire transfer completed!": [""],
      "The withdrawal has been aborted previously and can't be confirmed": [""],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.":
        [""],
      "Your balance is not enough for the operation.": [""],
      "Confirm the withdrawal operation": [""],
      "Wire transfer details": [""],
      "Taler Exchange operator's account": [""],
      "Taler Exchange operator's name": [""],
      Transfer: [""],
      "Authentication required": [""],
      "This operation was created with other username": [""],
      "Operation aborted": [""],
      "The wire transfer to the Taler Exchange operator's account was aborted, your balance was not affected.":
        [""],
      "You can close this page now or continue to the account page.": [""],
      Continue: [""],
      "Withdrawal confirmed": [""],
      "The wire transfer to the Taler operator has been initiated. You will soon receive the requested amount in your Taler wallet.":
        [""],
      Done: [""],
      "Operation canceled": [""],
      "The operation is marked as 'selected' but some step in the withdrawal failed":
        [""],
      "The account is selected but no withdrawal identification found.": [""],
      "There is a withdrawal identification but no account has been selected or the selected account is invalid.":
        [""],
      "No withdrawal ID found and no account has been selected or the selected account is invalid.":
        [""],
      "Operation not found": [""],
      "This operation is not known by the server. The operation id is wrong or the server deleted the operation information before reaching here.":
        [""],
      "Cotinue to dashboard": [""],
      "The Withdrawal URI is not valid": [""],
      'the bank backend is not supported. supported version "%1$s", server version "%2$s"':
        [""],
      "Internal error, please report.": [""],
      Preferences: [""],
      "Welcome, %1$s": [""],
      "Latest transactions": [""],
      Date: [""],
      Counterpart: [""],
      Subject: [""],
      sent: [""],
      received: [""],
      "invalid value": [""],
      to: [""],
      from: [""],
      "First page": [""],
      Next: [""],
      "History of public accounts": [""],
      "Currently, the bank is not accepting new registrations!": [""],
      "Missing name": [""],
      "Use letters and numbers only, and start with a lowercase letter": [""],
      "Passwords don't match": [""],
      "Server replied with invalid phone or email.": [""],
      "Registration is disabled because the bank ran out of bonus credit.": [
        "",
      ],
      "No enough permission to create that account.": [""],
      "That account id is already taken.": [""],
      "That username is already taken.": [""],
      "That username can't be used because is reserved.": [""],
      "Only admin is allow to set debt limit.": [""],
      "No information for the selected authentication channel.": [""],
      "Authentication channel is not supported.": [""],
      "Only admin can create accounts with second factor authentication.": [""],
      "Account registration": [""],
      "Repeat password": [""],
      Name: [""],
      "Create a random temporary user": [""],
      "Make a wire transfer": [""],
      "Wire transfer created!": [""],
      Accounts: [""],
      "A list of all business account in the bank.": [""],
      "Create account": [""],
      Balance: [""],
      Actions: [""],
      unknown: [""],
      "change password": [""],
      remove: [""],
      "Select a section": [""],
      "Last hour": [""],
      "Last day": [""],
      "Last month": [""],
      "Last year": [""],
      "Last Year": [""],
      "Trading volume on %1$s compared to %2$s": [""],
      Cashin: [""],
      Cashout: [""],
      Payin: [""],
      Payout: [""],
      "download stats as CSV": [""],
      "Descreased by": [""],
      "Increased by": [""],
      "Unable to create a cashout": [""],
      "The bank configuration does not support cashout operations.": [""],
      invalid: [""],
      "need to be higher due to fees": [""],
      "the total transfer at destination will be zero": [""],
      "Cashout created": [""],
      "Duplicated request detected, check if the operation succeded or try again.":
        [""],
      "The conversion rate was incorrectly applied": [""],
      "The account does not have sufficient funds": [""],
      "Cashouts are not supported": [""],
      "Missing cashout URI in the profile": [""],
      "Sending the confirmation message failed, retry later or contact the administrator.":
        [""],
      "Convertion rate": [""],
      Fee: [""],
      "To account": [""],
      "No cashout account": [""],
      "Before doing a cashout you need to complete your profile": [""],
      "Amount to send": [""],
      "Amount to receive": [""],
      "Total cost": [""],
      "Balance left": [""],
      "Before fee": [""],
      "Total cashout transfer": [""],
      "No cashout channel available": [""],
      "Before doing a cashout the server need to provide an second channel to confirm the operation":
        [""],
      "Second factor authentication": [""],
      Email: [""],
      "add a email in your profile to enable this option": [""],
      SMS: [""],
      "add a phone number in your profile to enable this option": [""],
      Details: [""],
      Delete: [""],
      Credentials: [""],
      Cashouts: [""],
      "it doesnt have the pattern of an IBAN number": [""],
      "it doesnt have the pattern of an email": [""],
      "should start with +": [""],
      "phone number can't have other than numbers": [""],
      "account identification in the bank": [""],
      "name of the person owner the account": [""],
      "Internal IBAN": [""],
      "if empty a random account number will be assigned": [""],
      "account identification for bank transfer": [""],
      Phone: [""],
      "Cashout IBAN": [""],
      "account number where the money is going to be sent when doing cashouts":
        [""],
      "Max debt": [""],
      "how much is user able to transfer after zero balance": [""],
      "Is this a Taler Exchange?": [""],
      "This server doesn't support second factor authentication.": [""],
      "Enable second factor authentication": [""],
      "Using email": [""],
      "Using SMS": [""],
      "Is this account public?": [""],
      "public accounts have their balance publicly accesible": [""],
      "Account updated": [""],
      "The rights to change the account are not sufficient": [""],
      "The username was not found": [""],
      "You can't change the legal name, please contact the your account administrator.":
        [""],
      "You can't change the debt limit, please contact the your account administrator.":
        [""],
      "You can't change the cashout address, please contact the your account administrator.":
        [""],
      "You can't change the contact data, please contact the your account administrator.":
        [""],
      'Account "%1$s"': [""],
      "Change details": [""],
      Update: [""],
      "password doesn't match": [""],
      "Password changed": [""],
      "Not authorized to change the password, maybe the session is invalid.": [
        "",
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.":
        [""],
      "Your current password doesn't match, can't change to a new password.": [
        "",
      ],
      "Update password": [""],
      "New password": [""],
      "Type it again": [""],
      "repeat the same password": [""],
      "Current password": [""],
      "your current password, for security": [""],
      Change: [""],
      "Can't delete the account": [""],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.":
        [""],
      "Account removed": [""],
      "No enough permission to delete the account.": [""],
      "The username was not found.": [""],
      "Can't delete a reserved username.": [""],
      "Can't delete an account with balance different than zero.": [""],
      "name doesn't match": [""],
      "You are going to remove the account": [""],
      "This step can't be undone.": [""],
      'Deleting account "%1$s"': [""],
      Verification: [""],
      "enter the account name that is going to be deleted": [""],
      'Account created with password "%1$s". The user must change the password on the next login.':
        [""],
      "Server replied that phone or email is invalid": [""],
      "The rights to perform the operation are not sufficient": [""],
      "Account username is already taken": [""],
      "Account id is already taken": [""],
      "Bank ran out of bonus credit.": [""],
      "Account username can't be used because is reserved": [""],
      "Can't create accounts": [""],
      "Only system admin can create accounts.": [""],
      "New business account": [""],
      Create: [""],
      "Cashout not supported.": [""],
      "Account not found.": [""],
      "Latest cashouts": [""],
      Created: [""],
      Confirmed: [""],
      "Total debit": [""],
      "Total credit": [""],
      Status: [""],
      never: [""],
      "Cashout for account %1$s": [""],
      "This cashout not found. Maybe already aborted.": [""],
      "Cashout not found. It may be also mean that it was already aborted.": [
        "",
      ],
      "Cashout was already confimed.": [""],
      "Cashout operation is not supported.": [""],
      "The cashout operation is already aborted.": [""],
      "Missing destination account.": [""],
      "Too many failed attempts.": [""],
      "The code for this cashout is invalid.": [""],
      "Cashout detail": [""],
      Debited: [""],
      Credited: [""],
      "Enter the confirmation code": [""],
      Abort: [""],
      Confirm: [""],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.":
        [""],
      "The operation was rejected due to insufficient funds.": [""],
      "Do not show this again": [""],
      Close: [""],
      "On this device": [""],
      'If you are using a web browser on desktop you should access your wallet with the GNU Taler WebExtension now or click the link if your WebExtension have the "Inject Taler support" option enabled.':
        [""],
      Start: [""],
      "On a mobile phone": [""],
      "Scan the QR code with your mobile device.": [""],
      "There is an operation already": [""],
      "Complete or cancel the operation in": [""],
      "Server responded with an invalid  withdraw URI": [""],
      "Withdraw URI: %1$s": [""],
      "The operation was rejected due to insufficient funds": [""],
      "Prepare your wallet": [""],
      "After using your wallet you will need to confirm or cancel the operation on this site.":
        [""],
      "You need a GNU Taler Wallet": [""],
      "If you don't have one yet you can follow the instruction in": [""],
      "Send money": [""],
      "to a %1$s wallet": [""],
      "Withdraw digital money into your mobile wallet or browser extension": [
        "",
      ],
      "operation ready": [""],
      "to another bank account": [""],
      "Make a wire transfer to an account with known bank account number.": [
        "",
      ],
      "Transfer details": [""],
      "This is a demo bank": [""],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s.":
        [""],
      "This part of the demo shows how a bank that supports Taler directly would work.":
        [""],
      "Pending account delete operation": [""],
      "Pending account update operation": [""],
      "Pending password update operation": [""],
      "Pending transaction operation": [""],
      "Pending withdrawal operation": [""],
      "Pending cashout operation": [""],
      "You can complete or cancel the operation in": [""],
      "Download bank stats": [""],
      "Include hour metric": [""],
      "Include day metric": [""],
      "Include month metric": [""],
      "Include year metric": [""],
      "Include table header": [""],
      "Add previous metric for compare": [""],
      "Fail on first error": [""],
      Download: [""],
      "downloading... %1$s": [""],
      "Download completed": [""],
      "click here to save the file in your computer": [""],
      "Challenge not found.": [""],
      "This user is not authorized to complete this challenge.": [""],
      "Too many attemps, try another code.": [""],
      "The confirmation code is wrong, try again.": [""],
      "The operation expired.": [""],
      "The operation failed.": [""],
      "The operation needs another confirmation to complete.": [""],
      "Account delete": [""],
      "Account update": [""],
      "Password update": [""],
      "Wire transfer": [""],
      Withdrawal: [""],
      "Confirm the operation": [""],
      "Send again": [""],
      "Send code": [""],
      "Operation details": [""],
      "Challenge details": [""],
      "Sent at": [""],
      "To phone": [""],
      "To email": [""],
      "Welcome to %1$s!": [""],
    },
  },
  domain: "messages",
  plural_forms: "nplurals=2; plural=n > 1;",
  lang: "fr",
  completeness: 0,
};

strings["es"] = {
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        plural_forms: "nplurals=2; plural=n != 1;",
        lang: "es",
      },
      "Operation failed, please report": [
        "La operaicón falló, por favor reportelo",
      ],
      "Request timeout": ["La petición al servidor agoto su tiempo"],
      "Request throttled": ["La petición al servidor interrumpida"],
      "Malformed response": ["Respuesta malformada"],
      "Network error": ["Error de conexión"],
      "Unexpected request error": ["Error de pedido inesperado"],
      "Unexpected error": ["Error inesperado"],
      "IBAN numbers usually have more that 4 digits": [
        "Los números IBAN usualmente tienen mas de 4 digitos",
      ],
      "IBAN numbers usually have less that 34 digits": [
        "Los números IBAN usualmente tienen menos de 34 digitos",
      ],
      "IBAN country code not found": ["Código de pais de IBAN no encontrado"],
      "IBAN number is not valid, checksum is wrong": [
        "El número IBAN no es válido, falló la verificación",
      ],
      "Max withdrawal amount": ["Monto máximo de extracción"],
      "Show withdrawal confirmation": ["Mostrar confirmación de extracción"],
      "Show demo description": ["Mostrar descripción de demo"],
      "Show install wallet first": ["Mostrar instalar la billetera primero"],
      "Use fast withdrawal form": ["Usar formulario de extracción rápida"],
      "Show debug info": ["Mostrar información de depuración"],
      "The reserve operation has been confirmed previously and can't be aborted":
        [
          "La operación en la reserva ya ha sido confirmada previamente y no puede ser abortada",
        ],
      "The operation id is invalid.": ["El id de operación es invalido."],
      "The operation was not found.": ["La operación no se encontró."],
      "If you have a Taler wallet installed in this device": [
        "Si tienes una billetera Taler instalada en este dispositivo",
      ],
      "You will see the details of the operation in your wallet including the fees (if applies). If you still don't have one you can install it following instructions in":
        [
          "Veras los detalles de la operación en tu billetera incluyendo comisiones (si aplicán). Si todavía no tienes una puedes instalarla siguiendo las instrucciones en",
        ],
      "this page": ["esta página"],
      Withdraw: ["Retirar"],
      "Or if you have the wallet in another device": [
        "O si tienes la billetera en otro dispositivo",
      ],
      "Scan the QR below to start the withdrawal.": [
        "Escanea el QR debajo para comenzar la extracción.",
      ],
      required: ["requerido"],
      "IBAN should have just uppercased letters and numbers": [
        "IBAN debería tener letras mayúsculas y números",
      ],
      "not valid": ["no válido"],
      "should be greater than 0": ["Debería ser mas grande que 0"],
      "balance is not enough": ["el saldo no es suficiente"],
      "does not follow the pattern": ["no tiene un patrón valido"],
      'only "IBAN" target are supported': [
        'solo cuentas "IBAN" son soportadas',
      ],
      'use the "amount" parameter to specify the amount to be transferred': [
        'usa el parámetro "amount" para indicar el monto a ser transferido',
      ],
      "the amount is not valid": ["el monto no es válido"],
      'use the "message" parameter to specify a reference text for the transfer':
        [
          'usa el parámetro "message" para indicar un texto de referencia en la transferencia',
        ],
      "The request was invalid or the payto://-URI used unacceptable features.":
        [
          "El pedido era inválido o el URI payto:// usado tiene características inaceptables.",
        ],
      "Not enough permission to complete the operation.": [
        "Sin permisos suficientes para completar la operación.",
      ],
      'The destination account "%1$s" was not found.': [
        'La cuenta de destino "%1$s" no fue encontrada.',
      ],
      "The origin and the destination of the transfer can't be the same.": [
        "El origen y destino de la transferencia no puede ser la misma.",
      ],
      "Your balance is not enough.": ["El saldo no es suficiente."],
      'The origin account "%1$s" was not found.': [
        'La cuenta origen "%1$s" no fue encontrada.',
      ],
      "Using a form": ["Usando un formulario"],
      "Import payto:// URI": ["Importando un URI payto://"],
      Recipient: ["Destinatario"],
      "IBAN of the recipient's account": [
        "Numero IBAN de la cuenta destinataria",
      ],
      "Transfer subject": ["Asunto de transferencia"],
      subject: ["asunto"],
      "some text to identify the transfer": [
        "algún texto para identificar la transferencia",
      ],
      Amount: ["Monto"],
      "amount to transfer": ["monto a transferir"],
      "payto URI:": ["payto URI:"],
      "uniform resource identifier of the target account": [
        "identificador de recurso uniforme de la cuenta destino",
      ],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [
        "payto://iban/[iban-destinatario]?message=[asunto]&amount=[%1$s:X.Y]",
      ],
      Cancel: ["Cancelar"],
      Send: ["Envíar"],
      "Missing username": ["Falta nombre de usuario"],
      "Missing password": ["Falta contraseña"],
      'Wrong credentials for "%1$s"': ['Credenciales incorrectas para "%1$s"'],
      "Account not found": ["Cuenta no encontrada"],
      Username: ["Nombre de usuario"],
      "username of the account": ["nombre de usuario de la cuenta"],
      Password: ["Contraseña"],
      "password of the account": ["contraseña de la cuenta"],
      Check: ["Verificar"],
      "Log in": ["Acceso"],
      Register: ["Registrarse"],
      "Wire transfer completed!": ["Transferencia bancaria completada!"],
      "The withdrawal has been aborted previously and can't be confirmed": [
        "La extracción fue abortada anteriormente y no puede ser confirmada",
      ],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.":
        [
          "La operación de extracción no puede ser confirmada antes de que una billetera acepte la transaccion.",
        ],
      "Your balance is not enough for the operation.": [
        "El saldo no es suficiente para la operación.",
      ],
      "Confirm the withdrawal operation": [
        "Confirme la operación de extracción",
      ],
      "Wire transfer details": ["Detalle de transferencia bancaria"],
      "Taler Exchange operator's account": [
        "Cuenta del operador del Taler Exchange",
      ],
      "Taler Exchange operator's name": [
        "Nombre del operador del Taler Exchange",
      ],
      Transfer: ["Transferencia"],
      "Authentication required": ["Autenticación requerida"],
      "This operation was created with other username": [
        "Esta operación fue creada con otro usuario",
      ],
      "Operation aborted": ["Operación abortada"],
      "The wire transfer to the Taler Exchange operator's account was aborted, your balance was not affected.":
        [
          "La transferencia bancaria a la cuenta del operador del Taler Exchange fue abortada, su saldo no fue afectado.",
        ],
      "You can close this page now or continue to the account page.": [
        "Ya puedes cerrar esta pagina or continuar a la página de estado de cuenta.",
      ],
      Continue: ["Continuar"],
      "Withdrawal confirmed": ["La extracción fue confirmada"],
      "The wire transfer to the Taler operator has been initiated. You will soon receive the requested amount in your Taler wallet.":
        [
          "La transferencia bancaria al operador Taler fue iniciada. Pronto recibirás el monto pedido en tu billetera Taler.",
        ],
      Done: ["Listo"],
      "Operation canceled": ["Operación cancelada"],
      "The operation is marked as 'selected' but some step in the withdrawal failed":
        [
          "La operación está marcada como 'seleccionada' pero algunos pasos en la extracción fallaron",
        ],
      "The account is selected but no withdrawal identification found.": [
        "La cuenta está seleccionada pero no se encontró el identificador de extracción.",
      ],
      "There is a withdrawal identification but no account has been selected or the selected account is invalid.":
        [
          "Hay un identificador de extracción pero la cuenta no ha sido seleccionada o la selccionada es inválida.",
        ],
      "No withdrawal ID found and no account has been selected or the selected account is invalid.":
        [
          "No hay un identificador de extracción y ninguna cuenta a sido seleccionada o la seleccionada es inválida.",
        ],
      "Operation not found": ["Operación no encontrada"],
      "This operation is not known by the server. The operation id is wrong or the server deleted the operation information before reaching here.":
        [
          "Esta operación no es conocida por el servidor. El identificador de operación es incorrecto o el server borró la información de la operación antes de llegar hasta aquí.",
        ],
      "Cotinue to dashboard": ["Continuar al panel"],
      "The Withdrawal URI is not valid": ["El URI de estracción no es válido"],
      'the bank backend is not supported. supported version "%1$s", server version "%2$s"':
        [
          'El servidor de bank no esta spoportado. Version soportada "%1$s", version del server "%2$s"',
        ],
      "Internal error, please report.": [
        "Error interno, por favor reporte el error.",
      ],
      Preferences: ["Preferencias"],
      "Welcome, %1$s": ["Bienvenido/a, %1$s"],
      "Latest transactions": ["Últimas transacciones"],
      Date: ["Fecha"],
      Counterpart: ["Contraparte"],
      Subject: ["Asunto"],
      sent: ["enviado"],
      received: ["recibido"],
      "invalid value": ["valor inválido"],
      to: ["hacia"],
      from: ["desde"],
      "First page": ["Primera página"],
      Next: ["Siguiente"],
      "History of public accounts": ["Historial de cuentas públicas"],
      "Currently, the bank is not accepting new registrations!": [
        "Actualmente, el banco no está aceptado nuevos registros!",
      ],
      "Missing name": ["Falta nombre"],
      "Use letters and numbers only, and start with a lowercase letter": [
        "Solo use letras y números, y comience con una letra minúscula",
      ],
      "Passwords don't match": ["La contraseña no coincide"],
      "Server replied with invalid phone or email.": [
        "El servidor repondio con teléfono o dirección de correo inválido.",
      ],
      "Registration is disabled because the bank ran out of bonus credit.": [
        "El registro está deshabilitado porque el banco se quedó sin crédito bonus.",
      ],
      "No enough permission to create that account.": [
        "Sin permisos suficientes para crear esa cuenta.",
      ],
      "That account id is already taken.": [
        "El identificador de cuenta ya está tomado.",
      ],
      "That username is already taken.": [
        "El nombre de usuario ya está tomado.",
      ],
      "That username can't be used because is reserved.": [
        "El nombre de usuario no puede ser usado porque esta reservado.",
      ],
      "Only admin is allow to set debt limit.": [
        "Solo el administrador tiene permitido cambiar el límite de deuda.",
      ],
      "No information for the selected authentication channel.": [
        "No hay información para el canal de autenticación seleccionado.",
      ],
      "Authentication channel is not supported.": [
        "Canal de autenticación no esta soportado.",
      ],
      "Only admin can create accounts with second factor authentication.": [
        "Solo el administrador puede crear cuentas con el segundo factor de autenticación.",
      ],
      "Account registration": ["Registro de cuenta"],
      "Repeat password": ["Repita la contraseña"],
      Name: ["Nombre"],
      "Create a random temporary user": ["Crear un usuario aleatorio temporal"],
      "Make a wire transfer": ["Hacer una transferencia bancaria"],
      "Wire transfer created!": ["Transferencia bancaria creada!"],
      Accounts: ["Cuentas"],
      "A list of all business account in the bank.": [
        "Una lista de todas las cuentas en el banco.",
      ],
      "Create account": ["Crear cuenta"],
      Balance: ["Saldo"],
      Actions: ["Acciones"],
      unknown: ["desconocido"],
      "change password": ["cambiar contraseña"],
      remove: ["elimiar"],
      "Select a section": ["Seleccione una sección"],
      "Last hour": ["Última hora"],
      "Last day": ["Último día"],
      "Last month": ["Último mes"],
      "Last year": ["Último año"],
      "Last Year": ["Último Año"],
      "Trading volume on %1$s compared to %2$s": [
        "Vólumen de comercio en %1$s comparado con %2$s",
      ],
      Cashin: ["Ingreso"],
      Cashout: ["Egreso"],
      Payin: ["Envios de dinero"],
      Payout: ["Recibos de dinero"],
      "download stats as CSV": ["descargar estadísticas en CSV"],
      "Descreased by": ["Descendiente por"],
      "Increased by": ["Ascendente por"],
      "Unable to create a cashout": ["Imposible crear un egreso"],
      "The bank configuration does not support cashout operations.": [
        "La configuración del banco no soporta operaciones de egreso.",
      ],
      invalid: ["inválido"],
      "need to be higher due to fees": [
        "necesita ser mayor debido a las comisiones",
      ],
      "the total transfer at destination will be zero": [
        "el total de la transferencia en destino será cero",
      ],
      "Cashout created": ["Egreso creado"],
      "Duplicated request detected, check if the operation succeded or try again.":
        [
          "Se detectó una petición duplicada, verifique si la operación tuvo éxito o intente otra vez.",
        ],
      "The conversion rate was incorrectly applied": [
        "La tasa de conversión se aplicó incorrectamente",
      ],
      "The account does not have sufficient funds": [
        "La cuenta no tiene fondos suficientes",
      ],
      "Cashouts are not supported": ["Egresos no están soportados"],
      "Missing cashout URI in the profile": [
        "Falta dirección de egreso en el perfíl",
      ],
      "Sending the confirmation message failed, retry later or contact the administrator.":
        [
          "El envío del mensaje de confirmación falló, intente mas tarde o contacte al administrador.",
        ],
      "Convertion rate": ["Tasa de conversión"],
      Fee: ["Comisión"],
      "To account": ["Hacia cuenta"],
      "No cashout account": ["No hay cuenta de egreso"],
      "Before doing a cashout you need to complete your profile": [
        "Antes de hacer un egreso necesita completar su perfíl",
      ],
      "Amount to send": ["Monto a enviar"],
      "Amount to receive": ["Monto a recibir"],
      "Total cost": ["Costo total"],
      "Balance left": ["Saldo remanente"],
      "Before fee": ["Antes de comisión"],
      "Total cashout transfer": ["Total de egreso"],
      "No cashout channel available": ["No hay canal de egreso disponible"],
      "Before doing a cashout the server need to provide an second channel to confirm the operation":
        [
          "Antes de hacer un egreso el servidor necesita proveer un segundo canal para confirmar la operación",
        ],
      "Second factor authentication": ["Segundo factor de autenticación"],
      Email: ["Correo eletrónico"],
      "add a email in your profile to enable this option": [
        "agrege un correo en su perfíl para habilitar esta opción",
      ],
      SMS: ["SMS"],
      "add a phone number in your profile to enable this option": [
        "agregue un número de teléfono para habilitar esta opción",
      ],
      Details: ["Detalles"],
      Delete: ["Borrar"],
      Credentials: ["Credenciales"],
      Cashouts: ["Egresos"],
      "it doesnt have the pattern of an IBAN number": [
        "no tiene el patrón de un número IBAN",
      ],
      "it doesnt have the pattern of an email": [
        "no tiene el patrón de un correo electrónico",
      ],
      "should start with +": ["debería comenzar con un +"],
      "phone number can't have other than numbers": [
        "número de teléfono no puede tener otra cosa que numeros",
      ],
      "account identification in the bank": [
        "identificador de cuenta en el banco",
      ],
      "name of the person owner the account": [
        "nombre de la persona dueña de la cuenta",
      ],
      "Internal IBAN": ["IBAN interno"],
      "if empty a random account number will be assigned": [
        "si está vacío un número de cuenta aleatorio será asignado",
      ],
      "account identification for bank transfer": [
        "identificador de cuenta para transferencia bancaria",
      ],
      Phone: ["Teléfono"],
      "Cashout IBAN": ["IBAN de egreso"],
      "account number where the money is going to be sent when doing cashouts":
        [
          "numero de cuenta donde el dinero será enviado cuando se ejecuten egresos",
        ],
      "Max debt": ["Máxima deuda"],
      "how much is user able to transfer after zero balance": [
        "cuanto tiene habilitado a transferir despues de un saldo en cero",
      ],
      "Is this a Taler Exchange?": ["Es un Taler Exchange?"],
      "This server doesn't support second factor authentication.": [
        "Este servidor no tiene soporte para segundo factor de autenticación.",
      ],
      "Enable second factor authentication": [
        "Hábilitar segundo factor de autenticación",
      ],
      "Using email": ["Usando correo eletrónico"],
      "Using SMS": ["Usando SMS"],
      "Is this account public?": ["Es una cuenta pública?"],
      "public accounts have their balance publicly accesible": [
        "las cuentas públicas tienen su saldo accesible al público",
      ],
      "Account updated": ["Cuenta actualizada"],
      "The rights to change the account are not sufficient": [
        "Los permisos para cambiar la cuenta no son suficientes",
      ],
      "The username was not found": ["El nombre de usaurio no se encontró"],
      "You can't change the legal name, please contact the your account administrator.":
        [
          "No puede cambiar el nombre legal, por favor contacte el administrador de la cuenta.",
        ],
      "You can't change the debt limit, please contact the your account administrator.":
        [
          "No puede cambiar el límite de deuda, por favor contacte el administrador de la cuenta.",
        ],
      "You can't change the cashout address, please contact the your account administrator.":
        [
          "No puede cambiar la dirección de egreso, por favor contacte al administrador de la cuenta.",
        ],
      "You can't change the contact data, please contact the your account administrator.":
        [
          "No puede cambiar los datos de contacto, por favor contacte al administrador de la cuenta.",
        ],
      'Account "%1$s"': ['Cuenta "%1$s"'],
      "Change details": ["Cambiar detalles"],
      Update: ["Actualizar"],
      "password doesn't match": ["la contraseña no coincide"],
      "Password changed": ["La contraseña cambió"],
      "Not authorized to change the password, maybe the session is invalid.": [
        "No está autorizado a cambiar el password, quizá la sesión es invalida.",
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.":
        [
          "Se necesita el password viejo para cambiar la contraseña. Si no lo tiene contacte a su administrador.",
        ],
      "Your current password doesn't match, can't change to a new password.": [
        "Su actual contraseña no coincide, no puede cambiar a una nueva contraseña.",
      ],
      "Update password": ["Actualizar contraseña"],
      "New password": ["Nueva contraseña"],
      "Type it again": ["Escribalo otra vez"],
      "repeat the same password": ["repita la misma contraseña"],
      "Current password": ["Contraseña actual"],
      "your current password, for security": [
        "su actual contraseña, por seguridad",
      ],
      Change: ["Cambiar"],
      "Can't delete the account": ["No se puede eliminar la cuenta"],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.":
        [
          "La cuenta no puede ser eliminada mientras tiene saldo. Primero aseguresé que el dueño haga un egreso completo.",
        ],
      "Account removed": ["Cuenta eliminada"],
      "No enough permission to delete the account.": [
        "No tiene permisos suficientes para eliminar la cuenta.",
      ],
      "The username was not found.": ["El nombr ede usuario no se encontró."],
      "Can't delete a reserved username.": [
        "No se puede eliminar un nombre de usuario reservado.",
      ],
      "Can't delete an account with balance different than zero.": [
        "No se puede eliminar una cuenta con saldo diferente a cero.",
      ],
      "name doesn't match": ["el nombre no coincide"],
      "You are going to remove the account": ["Está por eliminar la cuenta"],
      "This step can't be undone.": ["Este paso no puede ser deshecho."],
      'Deleting account "%1$s"': ['Borrando cuenta "%1$s"'],
      Verification: ["Verificación"],
      "enter the account name that is going to be deleted": [
        "ingrese el nombre de cuenta que será eliminado",
      ],
      'Account created with password "%1$s". The user must change the password on the next login.':
        [
          'Cuenta creada con contraseña "%1$s". El usuario debe cambiar la contraseña en el siguiente ingreso.',
        ],
      "Server replied that phone or email is invalid": [
        "El servidor respondió que el teléfono o correo eletrónico es invalido",
      ],
      "The rights to perform the operation are not sufficient": [
        "Los permisos para ejecutar la operación no son suficientes",
      ],
      "Account username is already taken": [
        "El nombre del usuario ya está tomado",
      ],
      "Account id is already taken": ["El id de cuenta ya está tomado"],
      "Bank ran out of bonus credit.": [
        "El banco no tiene mas crédito de bonus.",
      ],
      "Account username can't be used because is reserved": [
        "El nombre de usuario de la cuenta no puede userse porque está reservado",
      ],
      "Can't create accounts": ["No puede crear cuentas"],
      "Only system admin can create accounts.": [
        "Solo los administradores del sistema pueden crear cuentas.",
      ],
      "New business account": ["Nueva cuenta"],
      Create: ["Crear"],
      "Cashout not supported.": ["Egreso no soportado."],
      "Account not found.": ["Cuenta no encontrada."],
      "Latest cashouts": ["Últimos egresos"],
      Created: ["Creado"],
      Confirmed: ["Confirmado"],
      "Total debit": ["Débito total"],
      "Total credit": ["Crédito total"],
      Status: ["Estado"],
      never: ["nunca"],
      "Cashout for account %1$s": ["Egreso para cuenta %1$s"],
      "This cashout not found. Maybe already aborted.": [
        "Este egreso no se encontró. Quizá fue abortado.",
      ],
      "Cashout not found. It may be also mean that it was already aborted.": [
        "Egreso no econtrado. También puede significar que ya ha sido abortado.",
      ],
      "Cashout was already confimed.": ["Egreso ya fue confirmado."],
      "Cashout operation is not supported.": [
        "Operación de egreso no soportada.",
      ],
      "The cashout operation is already aborted.": [
        "La operación de egreso ya está abortada.",
      ],
      "Missing destination account.": ["Falta cuenta destino."],
      "Too many failed attempts.": ["Demasiados intentos fallidos."],
      "The code for this cashout is invalid.": [
        "El código para este egreso es invalido.",
      ],
      "Cashout detail": ["Detalles de egreso"],
      Debited: ["Debitado"],
      Credited: ["Acreditado"],
      "Enter the confirmation code": ["Ingresar el código de confirmación"],
      Abort: ["Abortar"],
      Confirm: ["Confirmar"],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.":
        [
          "No autorizado para hacer la operación, quizá la sesión haya expirado or cambió la contraseña.",
        ],
      "The operation was rejected due to insufficient funds.": [
        "La operación fue rechazada debido a saldo insuficiente.",
      ],
      "Do not show this again": ["No mostrar otra vez"],
      Close: ["Cerrar"],
      "On this device": ["En este dispositivo"],
      'If you are using a web browser on desktop you should access your wallet with the GNU Taler WebExtension now or click the link if your WebExtension have the "Inject Taler support" option enabled.':
        [
          'Si esta usando un explorador web de escritorio deberías acceder ahora a tu billletera con la GNU Taler WebExtension o hacer click en el link si tu extensión tiene la configuración "Inyectar soporte para Taler" habilitada.',
        ],
      Start: ["Comenzar"],
      "On a mobile phone": ["En un dispotivo mobile"],
      "Scan the QR code with your mobile device.": [
        "Escanear el código QR con tu dispotivo móvil.",
      ],
      "There is an operation already": ["Ya hay una operación"],
      "Complete or cancel the operation in": [
        "Completa o cancela la operación en",
      ],
      "Server responded with an invalid  withdraw URI": [
        "El servidor respondió con una URI de extracción inválida",
      ],
      "Withdraw URI: %1$s": ["URI de extracción: %1$s"],
      "The operation was rejected due to insufficient funds": [
        "La operación fue rechazada debido a fundos insuficientes",
      ],
      "Prepare your wallet": ["Prepare su billetera"],
      "After using your wallet you will need to confirm or cancel the operation on this site.":
        [
          "Despues de usar tu billetera necesitarás confirmar o cancelar la operación en este sitio.",
        ],
      "You need a GNU Taler Wallet": ["Necesitas una GNU Taler Wallet"],
      "If you don't have one yet you can follow the instruction in": [
        "Si no tienes una todavía puedes seguir las instrucciones en",
      ],
      "Send money": ["Enviar dinero"],
      "to a %1$s wallet": ["a una billetera %1$s"],
      "Withdraw digital money into your mobile wallet or browser extension": [
        "Extraer dinero digital a tu billetera móvil o extesión web",
      ],
      "operation ready": ["operación lista"],
      "to another bank account": ["a otra cuenta bancaria"],
      "Make a wire transfer to an account with known bank account number.": [
        "Hacer una transferencia bancaria a una cuenta con un número de cuenta conocido.",
      ],
      "Transfer details": ["Detalles de transferencia"],
      "This is a demo bank": ["Este es un banco de demostración"],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s.":
        [
          "Esta parte de la demostración muestra cómo funciona un banco que soporta Taler directamente. Además de usar tu propia cuenta de banco, también podrás ver el historial de transacciones de algunas %1$s.",
        ],
      "This part of the demo shows how a bank that supports Taler directly would work.":
        [
          "Esta parte de la demostración muetra como un banco que soporta Taler directamente funcionaría.",
        ],
      "Pending account delete operation": [
        "Operación pendiente de eliminación de cuenta",
      ],
      "Pending account update operation": [
        "Operación pendiente de actualización de cuenta",
      ],
      "Pending password update operation": [
        "Operación pendiente de actualización de password",
      ],
      "Pending transaction operation": ["Operación pendiente de transacción"],
      "Pending withdrawal operation": ["Operación pendiente de extracción"],
      "Pending cashout operation": ["Operación pendiente de egreso"],
      "You can complete or cancel the operation in": [
        "Puedes completar o cancelar la operación en",
      ],
      "Download bank stats": ["Descargar estadísticas del banco"],
      "Include hour metric": ["Incluir métrica horaria"],
      "Include day metric": ["Incluir métrica diaria"],
      "Include month metric": ["Incluir métrica mensual"],
      "Include year metric": ["Incluir métrica anual"],
      "Include table header": ["Incluir encabezado de tabla"],
      "Add previous metric for compare": [
        "Agregar métrica previa para comparar",
      ],
      "Fail on first error": ["Fallar en el primer error"],
      Download: ["Descargar"],
      "downloading... %1$s": ["descargando... %1$s"],
      "Download completed": ["Descarga completada"],
      "click here to save the file in your computer": [
        "click aquí para guardar el archivo en su computadora",
      ],
      "Challenge not found.": ["Desafío no encontrado."],
      "This user is not authorized to complete this challenge.": [
        "Este usuario no está autorizado para completar este desafío.",
      ],
      "Too many attemps, try another code.": [
        "Demasiados intentos, intente otro código.",
      ],
      "The confirmation code is wrong, try again.": [
        "El código de confirmación es erroneo, intente otra vez.",
      ],
      "The operation expired.": ["La operación expiró."],
      "The operation failed.": ["La operación falló."],
      "The operation needs another confirmation to complete.": [
        "La operación necesita otra confirmación para completar.",
      ],
      "Account delete": ["Eliminación de cuenta"],
      "Account update": ["Actualización de cuenta"],
      "Password update": ["Actualización de contraseña"],
      "Wire transfer": ["Transferencia bancaria"],
      Withdrawal: ["Extracción"],
      "Confirm the operation": ["Confirmar la operación"],
      "Send again": ["Enviar otra vez"],
      "Send code": ["Enviar código"],
      "Operation details": ["Detalles de operación"],
      "Challenge details": ["Detalles del desafío"],
      "Sent at": ["Enviado a"],
      "To phone": ["Al teléfono"],
      "To email": ["Al email"],
      "Welcome to %1$s!": ["Bienvenido a %1$s!"],
    },
  },
  domain: "messages",
  plural_forms: "nplurals=2; plural=n != 1;",
  lang: "es",
  completeness: 100,
};

strings["en"] = {
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        plural_forms: "nplurals=2; plural=(n != 1);",
        lang: "en",
      },
      "Operation failed, please report": [""],
      "Request timeout": [""],
      "Request throttled": [""],
      "Malformed response": [""],
      "Network error": [""],
      "Unexpected request error": [""],
      "Unexpected error": [""],
      "IBAN numbers usually have more that 4 digits": [""],
      "IBAN numbers usually have less that 34 digits": [""],
      "IBAN country code not found": [""],
      "IBAN number is not valid, checksum is wrong": [""],
      "Max withdrawal amount": [""],
      "Show withdrawal confirmation": [""],
      "Show demo description": [""],
      "Show install wallet first": [""],
      "Use fast withdrawal form": [""],
      "Show debug info": [""],
      "The reserve operation has been confirmed previously and can't be aborted":
        [""],
      "The operation id is invalid.": [""],
      "The operation was not found.": [""],
      "If you have a Taler wallet installed in this device": [""],
      "You will see the details of the operation in your wallet including the fees (if applies). If you still don't have one you can install it following instructions in":
        [""],
      "this page": [""],
      Withdraw: [""],
      "Or if you have the wallet in another device": [""],
      "Scan the QR below to start the withdrawal.": [""],
      required: [""],
      "IBAN should have just uppercased letters and numbers": [""],
      "not valid": [""],
      "should be greater than 0": [""],
      "balance is not enough": [""],
      "does not follow the pattern": [""],
      'only "IBAN" target are supported': [""],
      'use the "amount" parameter to specify the amount to be transferred': [
        "",
      ],
      "the amount is not valid": [""],
      'use the "message" parameter to specify a reference text for the transfer':
        [""],
      "The request was invalid or the payto://-URI used unacceptable features.":
        [""],
      "Not enough permission to complete the operation.": [""],
      'The destination account "%1$s" was not found.': [""],
      "The origin and the destination of the transfer can't be the same.": [""],
      "Your balance is not enough.": [""],
      'The origin account "%1$s" was not found.': [""],
      "Using a form": [""],
      "Import payto:// URI": [""],
      Recipient: [""],
      "IBAN of the recipient's account": [""],
      "Transfer subject": [""],
      subject: [""],
      "some text to identify the transfer": [""],
      Amount: [""],
      "amount to transfer": [""],
      "payto URI:": [""],
      "uniform resource identifier of the target account": [""],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [""],
      Cancel: [""],
      Send: [""],
      "Missing username": [""],
      "Missing password": [""],
      'Wrong credentials for "%1$s"': [""],
      "Account not found": [""],
      Username: [""],
      "username of the account": [""],
      Password: [""],
      "password of the account": [""],
      Check: [""],
      "Log in": [""],
      Register: [""],
      "Wire transfer completed!": [""],
      "The withdrawal has been aborted previously and can't be confirmed": [""],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.":
        [""],
      "Your balance is not enough for the operation.": [""],
      "Confirm the withdrawal operation": [""],
      "Wire transfer details": [""],
      "Taler Exchange operator's account": [""],
      "Taler Exchange operator's name": [""],
      Transfer: [""],
      "Authentication required": [""],
      "This operation was created with other username": [""],
      "Operation aborted": [""],
      "The wire transfer to the Taler Exchange operator's account was aborted, your balance was not affected.":
        [""],
      "You can close this page now or continue to the account page.": [""],
      Continue: [""],
      "Withdrawal confirmed": [""],
      "The wire transfer to the Taler operator has been initiated. You will soon receive the requested amount in your Taler wallet.":
        [""],
      Done: [""],
      "Operation canceled": [""],
      "The operation is marked as 'selected' but some step in the withdrawal failed":
        [""],
      "The account is selected but no withdrawal identification found.": [""],
      "There is a withdrawal identification but no account has been selected or the selected account is invalid.":
        [""],
      "No withdrawal ID found and no account has been selected or the selected account is invalid.":
        [""],
      "Operation not found": [""],
      "This operation is not known by the server. The operation id is wrong or the server deleted the operation information before reaching here.":
        [""],
      "Cotinue to dashboard": [""],
      "The Withdrawal URI is not valid": [""],
      'the bank backend is not supported. supported version "%1$s", server version "%2$s"':
        [""],
      "Internal error, please report.": [""],
      Preferences: [""],
      "Welcome, %1$s": [""],
      "Latest transactions": [""],
      Date: [""],
      Counterpart: [""],
      Subject: [""],
      sent: [""],
      received: [""],
      "invalid value": [""],
      to: [""],
      from: [""],
      "First page": [""],
      Next: [""],
      "History of public accounts": [""],
      "Currently, the bank is not accepting new registrations!": [""],
      "Missing name": [""],
      "Use letters and numbers only, and start with a lowercase letter": [""],
      "Passwords don't match": [""],
      "Server replied with invalid phone or email.": [""],
      "Registration is disabled because the bank ran out of bonus credit.": [
        "",
      ],
      "No enough permission to create that account.": [""],
      "That account id is already taken.": [""],
      "That username is already taken.": [""],
      "That username can't be used because is reserved.": [""],
      "Only admin is allow to set debt limit.": [""],
      "No information for the selected authentication channel.": [""],
      "Authentication channel is not supported.": [""],
      "Only admin can create accounts with second factor authentication.": [""],
      "Account registration": [""],
      "Repeat password": [""],
      Name: [""],
      "Create a random temporary user": [""],
      "Make a wire transfer": [""],
      "Wire transfer created!": [""],
      Accounts: [""],
      "A list of all business account in the bank.": [""],
      "Create account": [""],
      Balance: [""],
      Actions: [""],
      unknown: [""],
      "change password": [""],
      remove: [""],
      "Select a section": [""],
      "Last hour": [""],
      "Last day": [""],
      "Last month": [""],
      "Last year": [""],
      "Last Year": [""],
      "Trading volume on %1$s compared to %2$s": [""],
      Cashin: [""],
      Cashout: [""],
      Payin: [""],
      Payout: [""],
      "download stats as CSV": [""],
      "Descreased by": [""],
      "Increased by": [""],
      "Unable to create a cashout": [""],
      "The bank configuration does not support cashout operations.": [""],
      invalid: [""],
      "need to be higher due to fees": [""],
      "the total transfer at destination will be zero": [""],
      "Cashout created": [""],
      "Duplicated request detected, check if the operation succeded or try again.":
        [""],
      "The conversion rate was incorrectly applied": [""],
      "The account does not have sufficient funds": [""],
      "Cashouts are not supported": [""],
      "Missing cashout URI in the profile": [""],
      "Sending the confirmation message failed, retry later or contact the administrator.":
        [""],
      "Convertion rate": [""],
      Fee: [""],
      "To account": [""],
      "No cashout account": [""],
      "Before doing a cashout you need to complete your profile": [""],
      "Amount to send": [""],
      "Amount to receive": [""],
      "Total cost": [""],
      "Balance left": [""],
      "Before fee": [""],
      "Total cashout transfer": [""],
      "No cashout channel available": [""],
      "Before doing a cashout the server need to provide an second channel to confirm the operation":
        [""],
      "Second factor authentication": [""],
      Email: [""],
      "add a email in your profile to enable this option": [""],
      SMS: [""],
      "add a phone number in your profile to enable this option": [""],
      Details: [""],
      Delete: [""],
      Credentials: [""],
      Cashouts: [""],
      "it doesnt have the pattern of an IBAN number": [""],
      "it doesnt have the pattern of an email": [""],
      "should start with +": [""],
      "phone number can't have other than numbers": [""],
      "account identification in the bank": [""],
      "name of the person owner the account": [""],
      "Internal IBAN": [""],
      "if empty a random account number will be assigned": [""],
      "account identification for bank transfer": [""],
      Phone: [""],
      "Cashout IBAN": [""],
      "account number where the money is going to be sent when doing cashouts":
        [""],
      "Max debt": [""],
      "how much is user able to transfer after zero balance": [""],
      "Is this a Taler Exchange?": [""],
      "This server doesn't support second factor authentication.": [""],
      "Enable second factor authentication": [""],
      "Using email": [""],
      "Using SMS": [""],
      "Is this account public?": [""],
      "public accounts have their balance publicly accesible": [""],
      "Account updated": [""],
      "The rights to change the account are not sufficient": [""],
      "The username was not found": [""],
      "You can't change the legal name, please contact the your account administrator.":
        [""],
      "You can't change the debt limit, please contact the your account administrator.":
        [""],
      "You can't change the cashout address, please contact the your account administrator.":
        [""],
      "You can't change the contact data, please contact the your account administrator.":
        [""],
      'Account "%1$s"': [""],
      "Change details": [""],
      Update: [""],
      "password doesn't match": [""],
      "Password changed": [""],
      "Not authorized to change the password, maybe the session is invalid.": [
        "",
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.":
        [""],
      "Your current password doesn't match, can't change to a new password.": [
        "",
      ],
      "Update password": [""],
      "New password": [""],
      "Type it again": [""],
      "repeat the same password": [""],
      "Current password": [""],
      "your current password, for security": [""],
      Change: [""],
      "Can't delete the account": [""],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.":
        [""],
      "Account removed": [""],
      "No enough permission to delete the account.": [""],
      "The username was not found.": [""],
      "Can't delete a reserved username.": [""],
      "Can't delete an account with balance different than zero.": [""],
      "name doesn't match": [""],
      "You are going to remove the account": [""],
      "This step can't be undone.": [""],
      'Deleting account "%1$s"': [""],
      Verification: [""],
      "enter the account name that is going to be deleted": [""],
      'Account created with password "%1$s". The user must change the password on the next login.':
        [""],
      "Server replied that phone or email is invalid": [""],
      "The rights to perform the operation are not sufficient": [""],
      "Account username is already taken": [""],
      "Account id is already taken": [""],
      "Bank ran out of bonus credit.": [""],
      "Account username can't be used because is reserved": [""],
      "Can't create accounts": [""],
      "Only system admin can create accounts.": [""],
      "New business account": [""],
      Create: [""],
      "Cashout not supported.": [""],
      "Account not found.": [""],
      "Latest cashouts": [""],
      Created: [""],
      Confirmed: [""],
      "Total debit": [""],
      "Total credit": [""],
      Status: [""],
      never: [""],
      "Cashout for account %1$s": [""],
      "This cashout not found. Maybe already aborted.": [""],
      "Cashout not found. It may be also mean that it was already aborted.": [
        "",
      ],
      "Cashout was already confimed.": [""],
      "Cashout operation is not supported.": [""],
      "The cashout operation is already aborted.": [""],
      "Missing destination account.": [""],
      "Too many failed attempts.": [""],
      "The code for this cashout is invalid.": [""],
      "Cashout detail": [""],
      Debited: [""],
      Credited: [""],
      "Enter the confirmation code": [""],
      Abort: [""],
      Confirm: [""],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.":
        [""],
      "The operation was rejected due to insufficient funds.": [""],
      "Do not show this again": [""],
      Close: [""],
      "On this device": [""],
      'If you are using a web browser on desktop you should access your wallet with the GNU Taler WebExtension now or click the link if your WebExtension have the "Inject Taler support" option enabled.':
        [""],
      Start: [""],
      "On a mobile phone": [""],
      "Scan the QR code with your mobile device.": [""],
      "There is an operation already": [""],
      "Complete or cancel the operation in": [""],
      "Server responded with an invalid  withdraw URI": [""],
      "Withdraw URI: %1$s": [""],
      "The operation was rejected due to insufficient funds": [""],
      "Prepare your wallet": [""],
      "After using your wallet you will need to confirm or cancel the operation on this site.":
        [""],
      "You need a GNU Taler Wallet": [""],
      "If you don't have one yet you can follow the instruction in": [""],
      "Send money": [""],
      "to a %1$s wallet": [""],
      "Withdraw digital money into your mobile wallet or browser extension": [
        "",
      ],
      "operation ready": [""],
      "to another bank account": [""],
      "Make a wire transfer to an account with known bank account number.": [
        "",
      ],
      "Transfer details": [""],
      "This is a demo bank": [""],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s.":
        [""],
      "This part of the demo shows how a bank that supports Taler directly would work.":
        [""],
      "Pending account delete operation": [""],
      "Pending account update operation": [""],
      "Pending password update operation": [""],
      "Pending transaction operation": [""],
      "Pending withdrawal operation": [""],
      "Pending cashout operation": [""],
      "You can complete or cancel the operation in": [""],
      "Download bank stats": [""],
      "Include hour metric": [""],
      "Include day metric": [""],
      "Include month metric": [""],
      "Include year metric": [""],
      "Include table header": [""],
      "Add previous metric for compare": [""],
      "Fail on first error": [""],
      Download: [""],
      "downloading... %1$s": [""],
      "Download completed": [""],
      "click here to save the file in your computer": [""],
      "Challenge not found.": [""],
      "This user is not authorized to complete this challenge.": [""],
      "Too many attemps, try another code.": [""],
      "The confirmation code is wrong, try again.": [""],
      "The operation expired.": [""],
      "The operation failed.": [""],
      "The operation needs another confirmation to complete.": [""],
      "Account delete": [""],
      "Account update": [""],
      "Password update": [""],
      "Wire transfer": [""],
      Withdrawal: [""],
      "Confirm the operation": [""],
      "Send again": [""],
      "Send code": [""],
      "Operation details": [""],
      "Challenge details": [""],
      "Sent at": [""],
      "To phone": [""],
      "To email": [""],
      "Welcome to %1$s!": [""],
    },
  },
  domain: "messages",
  plural_forms: "nplurals=2; plural=(n != 1);",
  lang: "en",
  completeness: 100,
};

strings["de"] = {
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        plural_forms: "nplurals=2; plural=n != 1;",
        lang: "de",
      },
      "Operation failed, please report": [""],
      "Request timeout": [""],
      "Request throttled": [""],
      "Malformed response": [""],
      "Network error": [""],
      "Unexpected request error": [""],
      "Unexpected error": [""],
      "IBAN numbers usually have more that 4 digits": [""],
      "IBAN numbers usually have less that 34 digits": [""],
      "IBAN country code not found": [""],
      "IBAN number is not valid, checksum is wrong": [""],
      "Max withdrawal amount": [""],
      "Show withdrawal confirmation": [""],
      "Show demo description": [""],
      "Show install wallet first": [""],
      "Use fast withdrawal form": [""],
      "Show debug info": [""],
      "The reserve operation has been confirmed previously and can't be aborted":
        [""],
      "The operation id is invalid.": [""],
      "The operation was not found.": [""],
      "If you have a Taler wallet installed in this device": [""],
      "You will see the details of the operation in your wallet including the fees (if applies). If you still don't have one you can install it following instructions in":
        [""],
      "this page": [""],
      Withdraw: [""],
      "Or if you have the wallet in another device": [""],
      "Scan the QR below to start the withdrawal.": [""],
      required: [""],
      "IBAN should have just uppercased letters and numbers": [""],
      "not valid": [""],
      "should be greater than 0": [""],
      "balance is not enough": [""],
      "does not follow the pattern": [""],
      'only "IBAN" target are supported': [""],
      'use the "amount" parameter to specify the amount to be transferred': [
        "",
      ],
      "the amount is not valid": [""],
      'use the "message" parameter to specify a reference text for the transfer':
        [""],
      "The request was invalid or the payto://-URI used unacceptable features.":
        [""],
      "Not enough permission to complete the operation.": [""],
      'The destination account "%1$s" was not found.': [""],
      "The origin and the destination of the transfer can't be the same.": [""],
      "Your balance is not enough.": [""],
      'The origin account "%1$s" was not found.': [""],
      "Using a form": [""],
      "Import payto:// URI": [""],
      Recipient: [""],
      "IBAN of the recipient's account": [""],
      "Transfer subject": [""],
      subject: ["Verwendungszweck"],
      "some text to identify the transfer": [""],
      Amount: ["Betrag"],
      "amount to transfer": ["Betrag"],
      "payto URI:": [""],
      "uniform resource identifier of the target account": [""],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [""],
      Cancel: [""],
      Send: [""],
      "Missing username": [""],
      "Missing password": [""],
      'Wrong credentials for "%1$s"': [""],
      "Account not found": [""],
      Username: [""],
      "username of the account": [""],
      Password: [""],
      "password of the account": ["Buchungen auf öffentlich sichtbaren Konten"],
      Check: [""],
      "Log in": [""],
      Register: [""],
      "Wire transfer completed!": [""],
      "The withdrawal has been aborted previously and can't be confirmed": [""],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.":
        [""],
      "Your balance is not enough for the operation.": [""],
      "Confirm the withdrawal operation": ["Abhebung bestätigen"],
      "Wire transfer details": [""],
      "Taler Exchange operator's account": [""],
      "Taler Exchange operator's name": [""],
      Transfer: [""],
      "Authentication required": [""],
      "This operation was created with other username": [""],
      "Operation aborted": [""],
      "The wire transfer to the Taler Exchange operator's account was aborted, your balance was not affected.":
        [""],
      "You can close this page now or continue to the account page.": [""],
      Continue: [""],
      "Withdrawal confirmed": [""],
      "The wire transfer to the Taler operator has been initiated. You will soon receive the requested amount in your Taler wallet.":
        [""],
      Done: [""],
      "Operation canceled": [""],
      "The operation is marked as 'selected' but some step in the withdrawal failed":
        [""],
      "The account is selected but no withdrawal identification found.": [""],
      "There is a withdrawal identification but no account has been selected or the selected account is invalid.":
        [""],
      "No withdrawal ID found and no account has been selected or the selected account is invalid.":
        [""],
      "Operation not found": [""],
      "This operation is not known by the server. The operation id is wrong or the server deleted the operation information before reaching here.":
        [""],
      "Cotinue to dashboard": [""],
      "The Withdrawal URI is not valid": [""],
      'the bank backend is not supported. supported version "%1$s", server version "%2$s"':
        [""],
      "Internal error, please report.": [""],
      Preferences: [""],
      "Welcome, %1$s": [""],
      "Latest transactions": [""],
      Date: ["Datum"],
      Counterpart: ["Empfänger"],
      Subject: ["Verwendungszweck"],
      sent: [""],
      received: [""],
      "invalid value": [""],
      to: [""],
      from: [""],
      "First page": [""],
      Next: [""],
      "History of public accounts": [
        "Buchungen auf öffentlich sichtbaren Konten",
      ],
      "Currently, the bank is not accepting new registrations!": [""],
      "Missing name": [""],
      "Use letters and numbers only, and start with a lowercase letter": [""],
      "Passwords don't match": [""],
      "Server replied with invalid phone or email.": [""],
      "Registration is disabled because the bank ran out of bonus credit.": [
        "",
      ],
      "No enough permission to create that account.": [""],
      "That account id is already taken.": [""],
      "That username is already taken.": [""],
      "That username can't be used because is reserved.": [""],
      "Only admin is allow to set debt limit.": [""],
      "No information for the selected authentication channel.": [""],
      "Authentication channel is not supported.": [""],
      "Only admin can create accounts with second factor authentication.": [""],
      "Account registration": [""],
      "Repeat password": [""],
      Name: [""],
      "Create a random temporary user": [""],
      "Make a wire transfer": [""],
      "Wire transfer created!": [""],
      Accounts: ["Betrag"],
      "A list of all business account in the bank.": [""],
      "Create account": [""],
      Balance: [""],
      Actions: [""],
      unknown: [""],
      "change password": [""],
      remove: [""],
      "Select a section": [""],
      "Last hour": [""],
      "Last day": [""],
      "Last month": [""],
      "Last year": [""],
      "Last Year": [""],
      "Trading volume on %1$s compared to %2$s": [""],
      Cashin: [""],
      Cashout: [""],
      Payin: [""],
      Payout: [""],
      "download stats as CSV": [""],
      "Descreased by": [""],
      "Increased by": [""],
      "Unable to create a cashout": [""],
      "The bank configuration does not support cashout operations.": [""],
      invalid: [""],
      "need to be higher due to fees": [""],
      "the total transfer at destination will be zero": [""],
      "Cashout created": [""],
      "Duplicated request detected, check if the operation succeded or try again.":
        [""],
      "The conversion rate was incorrectly applied": [""],
      "The account does not have sufficient funds": [""],
      "Cashouts are not supported": [""],
      "Missing cashout URI in the profile": [""],
      "Sending the confirmation message failed, retry later or contact the administrator.":
        [""],
      "Convertion rate": [""],
      Fee: [""],
      "To account": [""],
      "No cashout account": [""],
      "Before doing a cashout you need to complete your profile": [""],
      "Amount to send": ["Betrag"],
      "Amount to receive": [""],
      "Total cost": [""],
      "Balance left": [""],
      "Before fee": [""],
      "Total cashout transfer": [""],
      "No cashout channel available": [""],
      "Before doing a cashout the server need to provide an second channel to confirm the operation":
        [""],
      "Second factor authentication": [""],
      Email: [""],
      "add a email in your profile to enable this option": [""],
      SMS: [""],
      "add a phone number in your profile to enable this option": [""],
      Details: [""],
      Delete: [""],
      Credentials: [""],
      Cashouts: [""],
      "it doesnt have the pattern of an IBAN number": [""],
      "it doesnt have the pattern of an email": [""],
      "should start with +": [""],
      "phone number can't have other than numbers": [""],
      "account identification in the bank": [""],
      "name of the person owner the account": [""],
      "Internal IBAN": [""],
      "if empty a random account number will be assigned": [""],
      "account identification for bank transfer": [""],
      Phone: [""],
      "Cashout IBAN": [""],
      "account number where the money is going to be sent when doing cashouts":
        [""],
      "Max debt": [""],
      "how much is user able to transfer after zero balance": [""],
      "Is this a Taler Exchange?": [""],
      "This server doesn't support second factor authentication.": [""],
      "Enable second factor authentication": [""],
      "Using email": [""],
      "Using SMS": [""],
      "Is this account public?": [""],
      "public accounts have their balance publicly accesible": [""],
      "Account updated": [""],
      "The rights to change the account are not sufficient": [""],
      "The username was not found": [""],
      "You can't change the legal name, please contact the your account administrator.":
        [""],
      "You can't change the debt limit, please contact the your account administrator.":
        [""],
      "You can't change the cashout address, please contact the your account administrator.":
        [""],
      "You can't change the contact data, please contact the your account administrator.":
        [""],
      'Account "%1$s"': [""],
      "Change details": [""],
      Update: [""],
      "password doesn't match": [""],
      "Password changed": [""],
      "Not authorized to change the password, maybe the session is invalid.": [
        "",
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.":
        [""],
      "Your current password doesn't match, can't change to a new password.": [
        "",
      ],
      "Update password": [""],
      "New password": [""],
      "Type it again": [""],
      "repeat the same password": [""],
      "Current password": [""],
      "your current password, for security": [""],
      Change: [""],
      "Can't delete the account": [""],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.":
        [""],
      "Account removed": [""],
      "No enough permission to delete the account.": [""],
      "The username was not found.": [""],
      "Can't delete a reserved username.": [""],
      "Can't delete an account with balance different than zero.": [""],
      "name doesn't match": [""],
      "You are going to remove the account": [""],
      "This step can't be undone.": [""],
      'Deleting account "%1$s"': [""],
      Verification: [""],
      "enter the account name that is going to be deleted": [""],
      'Account created with password "%1$s". The user must change the password on the next login.':
        [""],
      "Server replied that phone or email is invalid": [""],
      "The rights to perform the operation are not sufficient": [""],
      "Account username is already taken": [""],
      "Account id is already taken": [""],
      "Bank ran out of bonus credit.": [""],
      "Account username can't be used because is reserved": [""],
      "Can't create accounts": [""],
      "Only system admin can create accounts.": [""],
      "New business account": [""],
      Create: [""],
      "Cashout not supported.": [""],
      "Account not found.": [""],
      "Latest cashouts": [""],
      Created: [""],
      Confirmed: ["Bestätigen"],
      "Total debit": [""],
      "Total credit": [""],
      Status: [""],
      never: [""],
      "Cashout for account %1$s": [""],
      "This cashout not found. Maybe already aborted.": [""],
      "Cashout not found. It may be also mean that it was already aborted.": [
        "",
      ],
      "Cashout was already confimed.": [""],
      "Cashout operation is not supported.": [""],
      "The cashout operation is already aborted.": [""],
      "Missing destination account.": [""],
      "Too many failed attempts.": [""],
      "The code for this cashout is invalid.": [""],
      "Cashout detail": [""],
      Debited: [""],
      Credited: [""],
      "Enter the confirmation code": [""],
      Abort: [""],
      Confirm: ["Bestätigen"],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.":
        [""],
      "The operation was rejected due to insufficient funds.": [""],
      "Do not show this again": [""],
      Close: [""],
      "On this device": [""],
      'If you are using a web browser on desktop you should access your wallet with the GNU Taler WebExtension now or click the link if your WebExtension have the "Inject Taler support" option enabled.':
        [""],
      Start: [""],
      "On a mobile phone": [""],
      "Scan the QR code with your mobile device.": [""],
      "There is an operation already": [""],
      "Complete or cancel the operation in": ["Abhebung bestätigen"],
      "Server responded with an invalid  withdraw URI": [""],
      "Withdraw URI: %1$s": [""],
      "The operation was rejected due to insufficient funds": [""],
      "Prepare your wallet": [""],
      "After using your wallet you will need to confirm or cancel the operation on this site.":
        [""],
      "You need a GNU Taler Wallet": [""],
      "If you don't have one yet you can follow the instruction in": [""],
      "Send money": [""],
      "to a %1$s wallet": [""],
      "Withdraw digital money into your mobile wallet or browser extension": [
        "",
      ],
      "operation ready": [""],
      "to another bank account": [""],
      "Make a wire transfer to an account with known bank account number.": [
        "",
      ],
      "Transfer details": [""],
      "This is a demo bank": [""],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s.":
        [""],
      "This part of the demo shows how a bank that supports Taler directly would work.":
        [""],
      "Pending account delete operation": [""],
      "Pending account update operation": [""],
      "Pending password update operation": [""],
      "Pending transaction operation": [""],
      "Pending withdrawal operation": [""],
      "Pending cashout operation": [""],
      "You can complete or cancel the operation in": [""],
      "Download bank stats": [""],
      "Include hour metric": [""],
      "Include day metric": [""],
      "Include month metric": [""],
      "Include year metric": [""],
      "Include table header": [""],
      "Add previous metric for compare": [""],
      "Fail on first error": [""],
      Download: [""],
      "downloading... %1$s": [""],
      "Download completed": [""],
      "click here to save the file in your computer": [""],
      "Challenge not found.": [""],
      "This user is not authorized to complete this challenge.": [""],
      "Too many attemps, try another code.": [""],
      "The confirmation code is wrong, try again.": [""],
      "The operation expired.": [""],
      "The operation failed.": [""],
      "The operation needs another confirmation to complete.": [""],
      "Account delete": [""],
      "Account update": [""],
      "Password update": [""],
      "Wire transfer": [""],
      Withdrawal: ["Abhebung bestätigen"],
      "Confirm the operation": ["Abhebung bestätigen"],
      "Send again": [""],
      "Send code": [""],
      "Operation details": [""],
      "Challenge details": [""],
      "Sent at": [""],
      "To phone": [""],
      "To email": [""],
      "Welcome to %1$s!": [""],
    },
  },
  domain: "messages",
  plural_forms: "nplurals=2; plural=n != 1;",
  lang: "de",
  completeness: 4,
};
